import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEinHausInNiederoesterreich = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet ein Haus in Niederösterreich?" showCalc={false} />
            <Article>
                <p>
                    Haus, der Traum! Auf der Suche nach dem passenden Eigenheim ist vor allem eines wichtig: sich
                    möglichst früh einen Überblick über das Angebot zu verschaffen und ein Gespür für regionale
                    Unterschiede auf dem Markt zu entwickeln. In den Artikeln dieser Serie widmen wir uns daher den
                    einzelnen Bundesländern und geben dir einen ersten Überblick über das dortige Angebot. Diesmal sehen
                    wir uns den Niederösterreichischen Markt genauer an.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in Österreich – die derzeitige Situation</h2>
                <p>
                    Der Anstieg der Immobilienpreise bewegt sich in Österreich seit dem vierten Quartal 2020 im
                    zweistelligen Bereich. Ursache dafür sind aktuell sehr günstige Kreditzinsen, die die Nachfrage
                    befeuern. Im ersten Quartal des vergangenen Jahres kam es zu einem Preisanstieg in Höhe von 12,3 %,
                    im zweiten war eine Teuerung von 11,7 % zu verzeichnen. Im Gegensatz zu Einfamilienhäuser außerhalb
                    Wiens, deren Preisentwicklung vom ersten (12,9 %) zum zweiten Quartal 2021 (11,3 %) rückläufig war,
                    wurden gebrauchte Eigentumswohnungen zweiten Quartal um 14,3 % teurer. Im Allgemeinen lässt sich
                    zudem ein rascheres Preiswachstum bei Objekten außerhalb Wiens beobachten: Die Preise in der
                    Landeshauptstadt stiegen im ersten Quartal 2021 um 10,9, im zweiten um 10,7 %. In den anderen
                    Bundesländern kam es in diesem Zeitfenster dagegen zu Wachstumsraten von 14 bzw. 12,8 %. Dieser
                    Trend gilt im Besonderen für den Neubauwohnungsmarkt: Stiegen die Preise für neue Immobilien in Wien
                    2021 um 10,4 im ersten und von 10, 1 % zweiten Quartal, so kam es in den übrigen Bundesländern zu
                    einem Anstieg von 16,7 und 12,9 %.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in Niederösterreich – aktuelle Tendenzen</h2>
                <p>
                    Der niederösterreichische Immobilienmarkt zeichnet sich durch starke regionale Unterschiede aus.
                    Zwar steigt der Preis in gefragten Städten: So etwa in Krems, wo Häuser um 5,4 % teurer wurden, in
                    Wiener Neustadt, wo eine Teuerung von 9,66 % zu beobachten ist, oder in Gmünd, wo Grundstücke
                    aktuell sogar um 20 % mehr kosten. In anderen, tendenziell strukturschwachen Regionen gestaltet sich
                    die Preisentwicklung jedoch rückläufig. Gerade im ländlichen Umfeld werden Immobilien derzeit zu
                    besonders günstigen Konditionen angeboten. Doch auch wegen der attraktiven Mietrenditen, die
                    teilweise über 8 % liegen, ist Niederösterreich eine günstig Alternative zu anderen Bundesländern.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Das kostet ein Haus in Niederösterreich</h2>
                <p>
                    Im urbanen Gebiet der Landeshauptstadt St. Pölten kostet ein Quadratmeter Wohnfläche deines
                    zukünftigen Hauses etwas 1.586,25 €. Noch etwas günstiger wirst du in Korneuburg mit einem
                    Quadratmeterpreis von 1.547,89 € fündig. Ähnlich viel bezahlst du im Stadtgebiet von Wiener
                    Neustadt: 1.534,68 € beträgt der Preis für einen Quadratmeter hier. Ein wiederum billigere Option
                    ist Hollabrunn, wo du beim Kauf eines Hauses 1.295,56 € pro Quadratmeter bezahlen musst. Ein
                    besonders erschwingliche Wahl ist ein Haus in Waidhofen an der Thaya: Der Preis pro Quadratmeter
                    liegt hier bei 896,79 €.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEinHausInNiederoesterreich"}></BreadcrumbList>
            <ArticleStructuredData
                page={"wasKostetEinHausInNiederoesterreich"}
                heading={"Was kostet ein Haus in Niederösterreich?"}
            />
        </Layout>
    );
};

export default WasKostetEinHausInNiederoesterreich;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: ["header", "footer", "page.artikel.was-kostet-ein-haus-in-niederoesterreich", "mortgageCalc"]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
